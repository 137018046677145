.wrapper{
    display: flex;
    justify-content: center;
}

.container{
    display: flex;
    justify-content: center;
    border: 1px solid rgb(21 223 255 / 34%);
}

.item{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

.item > strong, .item > span{
    font-size: 15px;
    text-transform: uppercase;
}

.item::after{
    content: '|';
    padding-left: 30px;
    padding-right: 30px;
    color: rgb(21 223 255 / 34%);
}

.item:first-child::before{
    content: '';
    padding-left: 30px !important;
}

.item:last-child::after{
    content: '';
    padding-right: 0 !important;
}

.item > span{
    margin-left: 10px;
}

@media screen and (max-width: 900px) {
    .container{
        flex-direction: column;
    }

    .item::before, 
    .item::after
    {
        display: none !important;
    }

    .item{
        padding: 20px 20px;
        border-bottom: 1px solid #fd0;
    }

    .item:last-child{
        border-bottom: none;
    }

}